import * as _ from 'lodash';
import {  httpsCallable } from "firebase/functions";
import {functions} from '../firebase'


export const manageDeposit = async (userID:string,depositId: string,type:number) => {
  console.log(depositId,type)
  try {
    const data = await httpsCallable(functions,'manageDeposit')({
      userID:userID,
      depositId:depositId,
      type:type
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};


export const checkKhanBank = async (userID:string,accountID:string) => {
  try {
    const data = await httpsCallable(functions,'checkKhanTrans')({
      userID:userID,
      accountID:accountID
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};
export const makeKhanWithdraw = async (userID:string,withdrawID:string) => {
  try {
    const data = await httpsCallable(functions,'makeKhanWithdraw')({"userID":userID,"withdrawID":withdrawID});
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const appOTP = async (phone:string,type:string) => {
  try {
    const data = await httpsCallable(functions,'appOTP')({

      phone:phone,
      type:type
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const appConfirmOTP = async (payload:any) => {
  try {
    const data = await httpsCallable(functions,'appOTPConfirm')(payload);
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const appUserRegister = async (payload:any) => {
  try {
    const data = await httpsCallable(functions,'appUserRegister')(payload);
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const mobWithDraw= async (payload:any) => {
  try {
    const data = await httpsCallable(functions,'mobWithDraw')(payload);
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

