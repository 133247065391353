import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import { getFunctions } from "firebase/functions";
import firebase from 'firebase/compat/app';
import {getAuth} from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyBxjTCPOxw-TSFbqUj6sEql8h2u8Uuznh8",
  authDomain: "probetmn-953b1.firebaseapp.com",
  projectId: "probetmn-953b1",
  storageBucket: "probetmn-953b1.firebasestorage.app",
  messagingSenderId: "545603527282",
  appId: "1:545603527282:web:3879875abb7a43b43258eb",
  measurementId: "G-B3E03K41T5"
};

let app;

const initializeFirebase = () => {
  if (!firebase.apps.length) {
    app = firebase.initializeApp(firebaseConfig);
  }
};

initializeFirebase();

const auth = getAuth(app);
const firebaseAuth = firebase.auth(app);
const storage = firebase.storage(app);
const firestore = firebase.firestore(app);
const functions = getFunctions(app, "asia-southeast1"); 


export {app, firestore, storage, auth, firebaseAuth, functions};
