

import React, {  useState } from 'react';
import { Button, Input, Modal, message, Form, } from 'antd';
import * as _ from 'lodash';

import { mobWithDraw } from '../../helpers/firebase_functions';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  accountID: string;
  type: string;
  userID:string,
  depAcc:string
};

const WithdrawModal: React.FC<Props> = ({ accountID,type,userID, depAcc,open = false, toggle }) => {
  const [form] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();


  const onSave = async (values: any) => {
    setLoadingSave(true);
    const payload = {
      accountID: accountID,
      type:type,
        depAcc:depAcc,
        userID:userID,
      code: values.code,
      isAdmin:false
    }
    console.log(payload)
    const res = await mobWithDraw(payload) as any
    if (res.status) {
      messageApi.open({
        type: 'success',
        content: type+" ээс амжилттай татагдлаа. Миний Таталтууд жагсаалт дээр шинээр нэмэгдсэн таталтын үйлдэл хэсэгт байгаа ногоон товчийг дарж хүлээн авах данс руугаа шилжүүлж аваарай",
      });
      toggle(false)
    } else {
      messageApi.open({
        type: 'error',
        content: res.msg,
      });
    }

    setLoadingSave(false);

  };
  const onCancel = () => {
    setLoadingSave(false);
    toggle(false);
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title=""
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[]}
      >
        <div className="dialog-body">
          <div className="px-8">
            <div className="font-medium text-lg text-center my-5">{type} Таталт</div>
            <Form
              form={form}
              onFinish={onSave}
            >
              <div className="font-medium">{type} Таталтын Koдоо оруулна уу</div>
              <Form.Item
                rules={[{
                  required: true,
                }]}
                name="code">
                <Input
                  style={{ width: '70%' }}
                  placeholder='Koдоо оруулна уу'
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loadingSave}>
                  Шалгах
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default WithdrawModal;
