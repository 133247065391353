import { useContext, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router-dom';

import DataTable from '../../components/table/Index';
import { useDoc, useCollectionWithMulti, saveData } from '../../helpers/firestore';
import { WithdrawModel } from '../../types';
import { Button, Spin, Col, Row, message, Tag, Form, Input, Select, Space, Alert } from 'antd';
import { DollarCircleFilled } from '@ant-design/icons';

import { UserContext } from '../../context/app.contexts';
import { makeKhanWithdraw } from '../../helpers/firebase_functions'
import banks from './khanBranches.json'
import WithdrawModal from './WithdrawModal';
import { convertDateTimeFromTimestamp } from '../../helpers/utils';
const Withdraws = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { id, type, accountID } = useParams()
  const { user } = useContext(UserContext);
  const [openW, setOpenW] = useState(false);
  const toggleWModal = () => setOpenW(!openW);

  const [wazUser, wazUserLoading] = useDoc(`wazs/${user.uid}`);
  const [loadingSave, setLoadingSave] = useState(false);
  const [deposits, depositsLoading] = useCollectionWithMulti(`withdraws`, 'createdAt', { field: 'userID', op: '==', value: user.uid }, { field: 'depAcc', op: '==', value: id || '' }, 10);

  const [form] = Form.useForm();
  const onClickWith = () => {
    console.log(wazUser.withdraw)
    if (wazUser && wazUser.withdraw !== undefined)
      toggleWModal()
    else
      messageApi.error('Хүлээн авах банкны мэдээлэл бөглөгдөөгүй байна!')
  };

  useEffect(() => {
    if (!wazUserLoading && wazUser) {

      form.setFieldsValue(wazUser.withdraw)

    }
  }, [user, wazUserLoading]);

  const columns: ColumnsType<WithdrawModel> = [

    {
      key: 'amount',
      title: 'Таталтын дүн',
      dataIndex: 'amount',
      width: '20%',
      render: (amount: any) => { return numberWithCommas(amount) }
    },
    {
      key: 'bonus',
      title: '🎉 Bonus 🎉',
      dataIndex: 'bonus',
      width: 225,
      render: (bonus: any) => { return numberWithCommas(bonus) }
    },

    {
      key: 'statusMsg',
      title: 'Төлөв',
      dataIndex: 'statusMsg',
      width: '40%',
      render: (statusMsg: string, record) => {

        if (record.status == 'Transferring') {
          return <Tag color="processing"> {statusMsg}</Tag>
        }
        if (record.status == 'Withdrew') {
          return <Tag color="processing"> {statusMsg}</Tag>
        }

        if (record.status == 'Transferred') {
          return <Tag color="success"> {statusMsg}</Tag>
        }
        else {
          return (<>
            <Tag color="error"> {statusMsg}</Tag>
            <Button
              size="small"
              type="primary"
              className="bg-success"
              onClick={() => {
                onClickDetail(record.uid);
              }}>Дахин оролдох</Button>
          </>)
        }

      }
    },
    {
      key: 'createdAt',
      title: 'Melbet Огноо',
      dataIndex: 'createdAt',
      width: '20%',
      render: (createdAt: any) => { return convertDateTimeFromTimestamp(createdAt) }
    },

    {
      key: 'updatedAt',
      title: 'Банк гүйлгээ Огноо',
      dataIndex: 'updatedAt',
      width: '20%',
      render: (updatedAt: any) => { return convertDateTimeFromTimestamp(updatedAt) }
    },
  ];
  function numberWithCommas(x: any) {
    if (x)
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const onSave = async (values: any) => {
    setLoadingSave(true);


    await saveData('wazs', user.uid, {
      'withdraw': {
        ...values,
        accountName: ""
      }
    })
    messageApi.open({
      type: 'success',
      content: "Амжилттай хадгаллаа",
    });

    setLoadingSave(false);

  };
  const onClickDetail = async (withdrawID: string) => {
    console.log(withdrawID)
    messageApi.info('Таны хүсэлтийг шалгаж байна. Та түр хүлээнэ үү')
    const result = await makeKhanWithdraw(user.uid,withdrawID ) as any;
    console.log(result)

    if (result.status) {
      messageApi.info(result.msg)
    } else {
      messageApi.error(result.msg)
    }


  };
  return (
    <div>
      {contextHolder}
      <Row gutter={[16, 16]}>
        <Col xs={22} lg={12}>
          {!wazUserLoading && wazUser && (<>
            {!wazUser.withdraw && (
              <Alert message="Анхааруулга" description="Хүлээн авах Банк болон Дансны дугаараа оруулж хадгалсаны дараагаар өөрийн банкны данс руу татах боломжтой болно." type="warning" showIcon />
            )}

            <div className="card-container px-5 py-7">

              <Spin spinning={wazUserLoading}>
                <>
                  <div className="flex justify-between items-center mb-2">
                    <div className="text-lg font-medium">
                      Таталт хүлээн авах банк
                    </div>

                  </div>
                  <Form
                    form={form}
                    onFinish={onSave}
                    scrollToFirstError
                  >

                    <div className="font-medium">Хүлээн авах Банк</div>
                    <Form.Item
                      rules={[{
                        required: true,

                      }]}
                      name="payeeBankIdentifier"
                    >
                      <Select options={banks}
                        placeholder='Энд дарж банк сонгох'
                      />
                    </Form.Item>

                    <div className="font-medium">Дансны дугаар</div>
                    <Form.Item
                      rules={[{
                      }]}
                      name="account"
                    >
                      <Input placeholder='Дансны дугаараа оруулна уу' />
                    </Form.Item>

                    <Form.Item>
                      <Space>
                        <Button type="primary" htmlType="submit" loading={loadingSave}>
                          Хадгалах
                        </Button>

                        <Button type="primary" icon={<DollarCircleFilled />} onClick={onClickWith}>
                          Таталт хийх
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form>
                  <Alert message="Таталт хийх хаяг Cash" description={
                    <>
                      City : <strong>Ulaanbaatar</strong> Street:
                    { type=='1xbet' &&(
                      <>
                      Wazowski
                      </>
                    )}
                      { type=='melbet' &&(
                      <>
                        <strong>probetmn</strong>
                      </>
                    )}
                    
                    </>
                  }
        type="success" showIcon />
                

                <Alert message="🎉 Bonus 🎉" description="
              500K дээш 1% bonus " type="success" showIcon />
                </>
              </Spin>

            </div>

          </>)}
        </Col>
      </Row>
      <DataTable
        tableName="Миний Таталтууд"
        columns={columns}
        data={deposits}
        loading={depositsLoading}
      />
      <WithdrawModal accountID={accountID || ''} type={type || ''} userID={user.uid} depAcc={id || ''} open={openW} toggle={toggleWModal} />

    </div>);
};

export default Withdraws;
